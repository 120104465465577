.usage-range-picker--dropdown {
  .ant-picker-panel-container .ant-picker-presets > ul {
    > li:nth-child(6) {
      padding-top: 8px;
      border-top: 1px solid #d2d2d2;
      border-radius: 0;
      font-size: 12px;
      color: #696969;
      font-weight: bold;

      &:hover {
        background: transparent;
        cursor: default;
      }
    }
  }
}

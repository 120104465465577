.progressbar-double {
  width: calc(100% - 1.7px);
  overflow: hidden;
  position: relative;
  background: #0044cc0c;
  border: 1px solid #0044cc33;
  font-size: 11px;
  color: white;

  &.progressbar-double-danger {
    border: 1px solid rgba(255, 77, 79, 0.2);
  }

  &,
  .progressbar-double__background-bar,
  .progressbar-double__secondary-bar,
  .progressbar-double__main-bar {
    height: 14px;
    box-sizing: content-box;
    border-radius: 20px;
  }

  .progressbar-double__background-bar,
  .progressbar-double__secondary-bar,
  .progressbar-double__main-bar {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    min-width: 0;
  }

  .progressbar-double__background-bar {
    width: 100%;
    color: #0044cc;
  }

  .progressbar-double__secondary-bar {
    background: #0044cc33;

    &.progressbar-double__secondary-bar-danger {
      background: rgba(255, 77, 79, 0.2);
    }
  }

  .progressbar-double__main-bar {
    background: #0044ccff;

    &.progressbar-double__main-bar-danger {
      background: rgba(255, 77, 79);
    }
  }

  &.progressbar-description {
    &,
    .progressbar-double__background-bar,
    .progressbar-double__secondary-bar,
    .progressbar-double__main-bar {
      height: 24px;
      border-radius: 4px;
      line-height: 1;
    }

    .progressbar-double__background-bar,
    .progressbar-double__secondary-bar,
    .progressbar-double__main-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }
  }
}

.progressbar-double__legend-main,
.progressbar-double__legend-secondary,
.progressbar-double__legend-background,
.progressbar-double__legend-danger-main,
.progressbar-double__legend-danger-secondary {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:before {
    content: ' ';
    display: inline-block;
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-right: 4px;
  }
}

.progressbar-double__legend-main:before {
  background: #0044ccff;
}

.progressbar-double__legend-secondary:before {
  background: #0044cc33;
}

.progressbar-double__legend-background:before {
  background: #0044cc0c;
  border: 1px solid #0044cc33;
}

.progressbar-double__legend-danger-main:before {
  background: rgba(255, 77, 79);
}

.progressbar-double__legend-danger-secondary:before {
  background: rgba(255, 77, 79, 0.2);
}
